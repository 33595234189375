import * as React from "react";
import { Helmet } from "react-helmet";
import Header from "./Header";
import { useState } from "react";
import { MainSiteWrapper } from "./_mainComponents";

export const HelmetComponent = () => {
    return (
        <Helmet title="" defer={true}>
            <meta name="description" content="" />
            <meta name="keywords" content="" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
            />
            <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;800&display=swap" rel="stylesheet"/>
        </Helmet>
    );
};

export const Layout = ({children}:{children: any}) => {

    const [navbarVersion, setNavbarVersion] = useState(0);
    const applyHeader = (e:any) => {
        console.log(e.target.scrollTop);
        if(e.target.scrollTop > 40){
            setNavbarVersion(1);
        }else{
            setNavbarVersion(0)
        }
    }
    return (

        <MainSiteWrapper className="mainWrapper" onScroll={applyHeader}>
            <HelmetComponent />
            <Header imean={navbarVersion}/>
            {children}
        </MainSiteWrapper>
    )
}
