import styled from "@emotion/styled";
import Image from "gatsby-image";
import GatsbyImage from "gatsby-image";

export const MainSiteWrapper = styled.div`
  background: #dfdfdf;
  height: 100vh;
  width: 100vw;
  font-family: "Poppins", sans-serif;
  scroll-snap-type: y mandatory;

  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #7e7e7e;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.56);
    background: #ababab;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  padding:1em;
  padding-top:3em;
  width: calc(100% - 2em);
  justify-content: center;
  position: fixed;
  z-index: 40;
  
  @media (min-width: 900px){
    padding:2em;
    width: calc(100% - 4em);
  }
`

export const MainHeaderWrapper = styled.div<{imean: number}>`
  display: flex;
  width: calc(100% - 1em);
  max-width: 1280px;
  justify-content: space-between;
  height: ${props => props.imean > 0 ? '4em' : '6em'};
  transition: all 250ms ease-in-out;
  border-radius: 50px;
  background: #e0e0e0;
  box-shadow:  20px 20px 60px #bebebe,
             -20px -20px 60px #ffffff;


  @media (min-width: 900px){
    width: calc(100% - 4em);
  }
`;

export const LanguageSelection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 3em;

  img {
    width: 1em;
  }
  @media (min-width: 900px) {
    width: 10em;

    img {
      width: 2em;
    }
  }
`;

export const PositionMock = styled.div`
  flex: 1;
`;

export const LogoWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left:3em;
  padding-right:3em;
  img {
    height: 13em;
    width: 100%;
    margin-bottom: -2em;
  }
`;
export const LogosWrapper = styled.div<{imean: number}>`
  flex: 1;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  height: 5em;
  width: 5em;
  transform: translate(0,0);
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 250ms cubic-bezier(.75,-0.87,.54,1.58) 0.01s;
    &:nth-child(odd) {
      ${props=>props.imean>0?'transform: translate(-30%,17%);':''}
    }
    &:nth-child(even) {
      ${props=>props.imean>0?'transform: translate(30%,-17%);':''}
    }
  }
`;

export const PageNavigation = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  padding-right:3em;
`;

export const EmptyDiv = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
`;

export const PageNavigationButtons = styled.div`
  font-family: "Jake-Poppins", Arial, sans-serif;
  display: none;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  width: 100%;
  text-transform: uppercase;
  
  @media (min-width: 900px){
    display: flex;
  }

  a {
    font-family: "Jake-Poppins", Arial, sans-serif;
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    width: 100%;
    text-transform: uppercase;
    text-decoration: none;
    color: #252525;
  }
`;

export const JumbotronWrapper = styled.div`
  max-width: 100vw;
  max-height: calc(100vh - 13em);
  position: relative;
  min-height: 100vh;
  display: flex;
`;

export const MainDuckImage = styled(GatsbyImage)`
  position: absolute;
  transform: translateX(20%);
`;

export const JumbotronTexts = styled.div`
  position: absolute;
  top: 20%;
  left: 10%;
  z-index: 10;
`;

export const JumbotronTitle = styled.div`
  font-weight: 800;
  font-size: 2.5em;
  line-height: 0.9em;
  text-transform: uppercase;
  z-index: 10;

  @media (min-width: 900px) {
    font-size: 7em;
  }
`;
export const JumbotronSubTitle = styled.div`
  font-weight: 300;
  font-size: 2em;
  line-height: 1em;
  color: #101010;
  text-transform: uppercase;
  z-index: 5;

  @media (min-width: 900px) {
    font-size: 4em;
  }
`;

export const WhoamiWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const WhoamiTitle = styled.div`
  font-size: 2em;
  font-weight: 800;
  position: absolute;
  left: 35%;
  z-index: 5;

  @media (min-width: 900px) {
    font-size: 5em;
  }
`;

export const FloatingTexts = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
`;
export const FloatingLine = styled.div`
  width: 100%;
  transform: translateX(-50%);
  white-space: nowrap;
  font-size: 2em;
  font-weight: 800;
  color: #cccccc;
  text-transform: uppercase;
  z-index: -4;

  @media (min-width: 900px) {
    font-size: 7em;
  }
`;
