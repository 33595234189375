import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import {
  EmptyDiv, HeaderWrapper,
  JumbotronTitle,
  LanguageSelection,
  LogosWrapper,
  LogoWrapper,
  MainHeaderWrapper,
  PageNavigation,
  PageNavigationButtons,
  PositionMock,
} from "./_mainComponents";
import { changeLocale, Link, useIntl } from "gatsby-plugin-intl";

export const LinkStyled = ({to, children}:{to:string, children: any}) => {
  return <Link to={to} activeStyle={{color: "#c2c2c2"}}>{children}</Link>;
}

const Header = ({ imean = 0 }) => {
  const intl = useIntl();
  return (
    <HeaderWrapper>
      <MainHeaderWrapper imean={imean}>
        {/*<EmptyDiv />*/}
        <LogoWrapper>
          <Link to="/">
            <LogosWrapper imean={imean}>
              <img src="/logoTop.svg" alt="Main Jake logo" />
              <img src="/logoBottom.svg" alt="Main Jake logo" />
            </LogosWrapper>
          </Link>
        </LogoWrapper>
        <PageNavigationButtons>
          <LinkStyled to={"/projects"}>Projects</LinkStyled>
          <LinkStyled to={"/posts"}>Posts</LinkStyled>
          <LinkStyled to={"/memes"}>Memes</LinkStyled>
          <LinkStyled to={"/ducks"}>Ducks</LinkStyled>
          {/*<div>{intl.formatMessage({ id: "projects" })}</div>
          <div>{intl.formatMessage({ id: "photos" })}</div>
          <div>{intl.formatMessage({ id: "aboutme" })}</div>
          <div>{intl.formatMessage({ id: "posts" })}</div>
          <div>{intl.formatMessage({ id: "contact" })}</div>*/}
        </PageNavigationButtons>
        <PageNavigation>
          <LanguageSelection>
            <img
              src="/gb.svg"
              alt="Great Britain flag"
              onClick={() => {
                changeLocale("en");
              }}
            />
            <img
              src="/pl.svg"
              alt="Poland flag"
              onClick={() => {
                changeLocale("pl");
              }}
            />
            <img
              src="/se.svg"
              alt="Sweden flag"
              onClick={() => {
                changeLocale("se");
              }}
            />
          </LanguageSelection>
        </PageNavigation>
      </MainHeaderWrapper>
    </HeaderWrapper>
  );
};

export default Header;
